$icomoon-font-family: "Zipabout-2024-2" !default;
$icomoon-font-path: "../fonts" !default;

$zip-icon-fac_seat: "\e900";
$zip-icon-fac_tickets: "\e901";
$zip-icon-fac_toilets: "\e902";
$zip-icon-int_alert: "\e903";
$zip-icon-int_arrow_down: "\e904";
$zip-icon-int_arrow_left: "\e905";
$zip-icon-int_arrow_right: "\e906";
$zip-icon-int_arrow_round_left: "\e907";
$zip-icon-int_arrow_round_right: "\e908";
$zip-icon-int_arrow_up: "\e909";
$zip-icon-int_arrow_updown: "\e90a";
$zip-icon-int_close: "\e90b";
$zip-icon-int_datepicker: "\e90c";
$zip-icon-int_delete: "\e90d";
$zip-icon-int_edit: "\e90e";
$zip-icon-int_elipsis: "\e90f";
$zip-icon-int_fave_off: "\e910";
$zip-icon-int_fave_on: "\e911";
$zip-icon-int_gear: "\e912";
$zip-icon-int_help: "\e913";
$zip-icon-int_info: "\e914";
$zip-icon-int_location: "\e915";
$zip-icon-Int_Locked: "\e916";
$zip-icon-int_messaging: "\e917";
$zip-icon-int_mute_off: "\e918";
$zip-icon-int_mute_on: "\e919";
$zip-icon-int_prefs: "\e91a";
$zip-icon-int_share: "\e91b";
$zip-icon-int_switch: "\e91c";
$zip-icon-int_timepicker: "\e91d";
$zip-icon-int_cross: "\e925";
$zip-icon-int_tick: "\e926";
$zip-icon-jp_accessible: "\e91e";
$zip-icon-jp_best: "\e91f";
$zip-icon-jp_cheapest: "\e920";
$zip-icon-jp_flag: "\e921";
$zip-icon-jp_greenest: "\e922";
$zip-icon-jp_healthiest: "\e923";
$zip-icon-jp_multimodal: "\e924";
$zip-icon-jp_quickest: "\e929";
$zip-icon-jp_repeat: "\e92a";
$zip-icon-logo_w3w: "\e92b";
$zip-icon-logo_zip_inverted_45: "\e92c";
$zip-icon-logo_zip_inverted: "\e92d";
$zip-icon-logo_zip: "\e92e";
$zip-icon-mode_bus_auton: "\e92f";
$zip-icon-mode_bus_stop: "\e930";
$zip-icon-mode_bus: "\e931";
$zip-icon-mode_cablecar: "\e932";
$zip-icon-mode_cycle: "\e933";
$zip-icon-mode_drive_drop: "\e934";
$zip-icon-mode_drive_parking: "\e935";
$zip-icon-mode_drive_parkride: "\e936";
$zip-icon-mode_drive_traffic: "\e937";
$zip-icon-mode_drive: "\e938";
$zip-icon-mode_fly_stop: "\e939";
$zip-icon-mode_fly: "\e93a";
$zip-icon-mode_metro_stop: "\e93b";
$zip-icon-mode_metro: "\e93c";
$zip-icon-mode_rail_stop: "\e93d";
$zip-icon-mode_rail: "\e93e";
$zip-icon-mode_scooter: "\e93f";
$zip-icon-mode_ship_stop: "\e940";
$zip-icon-mode_ship: "\e941";
$zip-icon-mode_taxi: "\e942";
$zip-icon-mode_tram_stop: "\e943";
$zip-icon-mode_tram: "\e944";
$zip-icon-mode_wait_long: "\e945";
$zip-icon-mode_wait_short: "\e946";
$zip-icon-mode_wait: "\e947";
$zip-icon-mode_walk: "\e948";
$zip-icon-poi_attraction: "\e949";
$zip-icon-poi_bar: "\e94a";
$zip-icon-poi_cafe: "\e94b";
$zip-icon-poi_camping: "\e94c";
$zip-icon-poi_education: "\e94d";
$zip-icon-poi_food: "\e94e";
$zip-icon-poi_generic: "\e94f";
$zip-icon-poi_health: "\e950";
$zip-icon-poi_home: "\e951";
$zip-icon-poi_hospital: "\e952";
$zip-icon-poi_hotel: "\e953";
$zip-icon-poi_library: "\e954";
$zip-icon-poi_petrol: "\e955";
$zip-icon-poi_shop_generic: "\e956";
$zip-icon-poi_shop_supermarket: "\e957";
$zip-icon-poi_sport: "\e958";
$zip-icon-poi_theatre: "\e959";
$zip-icon-poi_work: "\e95a";
$zip-icon-poi_worship: "\e95b";
$zip-icon-wea_airqual1: "\e95c";
$zip-icon-wea_airqual2: "\e95d";
$zip-icon-wea_cloudy_light: "\e95e";
$zip-icon-wea_cloudy_overcast: "\e95f";
$zip-icon-wea_cloudy_windy: "\e960";
$zip-icon-wea_cloudy: "\e961";
$zip-icon-wea_hall: "\e962";
$zip-icon-wea_moon_full: "\e963";
$zip-icon-wea_moon_half: "\e964";
$zip-icon-wea_rain_heavy: "\e965";
$zip-icon-wea_rain: "\e966";
$zip-icon-wea_snow_light: "\e967";
$zip-icon-wea_snow: "\e968";
$zip-icon-wea_storm_heavy: "\e969";
$zip-icon-wea_storm_thunder: "\e96a";
$zip-icon-wea_sun_fog: "\e96b";
$zip-icon-wea_sun_full: "\e96c";
$zip-icon-wea_sun_mist: "\e96d";
$zip-icon-wea_sun_partial: "\e96e";
$zip-icon-wea_wind_extreme: "\e96f";
$zip-icon-wea_wind: "\e970";
$zip-icon-int_search: "\e927";