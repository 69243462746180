@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?yi5k81");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?yi5k81#iefix") format("embedded-opentype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?yi5k81") format("truetype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?yi5k81") format("woff"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?yi5k81##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="zip-icon-"],
[class*=" zip-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zip-icon-jp_flag {
  &:before {
    content: $zip-icon-jp_flag;
  }
}

.zip-icon-jp_multimodal {
  &:before {
    content: $zip-icon-jp_multimodal;
  }
}

.zip-icon-mode_transit {
  &:before {
    content: $zip-icon-jp_multimodal;
  }
}

.zip-icon-fac_seat {
  &:before {
    content: $zip-icon-fac_seat;
  }
}

.zip-icon-mode_bus {
  &:before {
    content: $zip-icon-mode_bus;
  }
}

.zip-icon-mode_bus_stop {
  &:before {
    content: $zip-icon-mode_bus_stop;
  }
}

.zip-icon-mode_metro {
  &:before {
    content: $zip-icon-mode_metro;
  }
}

.zip-icon-mode_metro_stop {
  &:before {
    content: $zip-icon-mode_metro_stop;
  }
}

.zip-icon-fac_tickets {
  &:before {
    content: $zip-icon-fac_tickets;
  }
}

.zip-icon-fac_toilets {
  &:before {
    content: $zip-icon-fac_toilets;
  }
}

.zip-icon-int_alert {
  &:before {
    content: $zip-icon-int_alert;
  }
}

.zip-icon-int_arrow_down {
  &:before {
    content: $zip-icon-int_arrow_down;
  }
}

.zip-icon-int_arrow_left {
  &:before {
    content: $zip-icon-int_arrow_left;
  }
}

.zip-icon-int_arrow_right {
  &:before {
    content: $zip-icon-int_arrow_right;
  }
}

.zip-icon-int_arrow_round_left {
  &:before {
    content: $zip-icon-int_arrow_round_left;
  }
}

.zip-icon-int_arrow_round_right {
  &:before {
    content: $zip-icon-int_arrow_round_right;
  }
}

.zip-icon-int_arrow_up {
  &:before {
    content: $zip-icon-int_arrow_up;
  }
}

.zip-icon-int_arrow_updown {
  &:before {
    content: $zip-icon-int_arrow_updown;
  }
}

.zip-icon-int_close {
  &:before {
    content: $zip-icon-int_close;
  }
}

.zip-icon-int_datepicker {
  &:before {
    content: $zip-icon-int_datepicker;
  }
}

.zip-icon-int_delete {
  &:before {
    content: $zip-icon-int_delete;
  }
}

.zip-icon-int_elipsis {
  &:before {
    content: $zip-icon-int_elipsis;
  }
}

.zip-icon-int_fave_off {
  &:before {
    content: $zip-icon-int_fave_off;
  }
}

.zip-icon-int_fave_on {
  &:before {
    content: $zip-icon-int_fave_on;
  }
}

.zip-icon-int_gear {
  &:before {
    content: $zip-icon-int_gear;
  }
}

.zip-icon-int_help {
  &:before {
    content: $zip-icon-int_help;
  }
}

.zip-icon-int_info {
  &:before {
    content: $zip-icon-int_info;
  }
}

.zip-icon-int_search {
  &:before {
    content: $zip-icon-int_search;
  }
}

.zip-icon-int_location {
  &:before {
    content: $zip-icon-int_location;
  }
}

.zip-icon-Int_Locked {
  &:before {
    content: $zip-icon-Int_Locked;
  }
}

.zip-icon-int_messaging {
  &:before {
    content: $zip-icon-int_messaging;
  }
}

.zip-icon-int_mute_off {
  &:before {
    content: $zip-icon-int_mute_off;
  }
}

.zip-icon-int_mute_on {
  &:before {
    content: $zip-icon-int_mute_on;
  }
}

.zip-icon-int_prefs {
  &:before {
    content: $zip-icon-int_prefs;
  }
}

.zip-icon-int_share {
  &:before {
    content: $zip-icon-int_share;
  }
}

.zip-icon-int_switch {
  &:before {
    content: $zip-icon-int_switch;
  }
}

.zip-icon-int_timepicker {
  &:before {
    content: $zip-icon-int_timepicker;
  }
}

.zip-icon-int_cross {
  &:before {
    content: $zip-icon-int_cross;
  }
}

.zip-icon-int_tick {
  &:before {
    content: $zip-icon-int_tick;
  }
}

.zip-icon-jp_accessible {
  &:before {
    content: $zip-icon-jp_accessible;
  }
}

.zip-icon-jp_best {
  &:before {
    content: $zip-icon-jp_best;
  }
}

.zip-icon-jp_cheapest {
  &:before {
    content: $zip-icon-jp_cheapest;
  }
}

.zip-icon-jp_greenest {
  &:before {
    content: $zip-icon-jp_greenest;
  }
}

.zip-icon-jp_healthiest {
  &:before {
    content: $zip-icon-jp_healthiest;
  }
}

.zip-icon-jp_quickest {
  &:before {
    content: $zip-icon-jp_quickest;
  }
}

.zip-icon-logo_w3w {
  &:before {
    content: $zip-icon-logo_w3w;
  }
}

.zip-icon-logo_zip_inverted_45 {
  &:before {
    content: $zip-icon-logo_zip_inverted_45;
  }
}

.zip-icon-logo_zip_inverted {
  &:before {
    content: $zip-icon-logo_zip_inverted;
  }
}

.zip-icon-logo_zip {
  &:before {
    content: $zip-icon-logo_zip;
  }
}

.zip-icon-mode_bus_auton {
  &:before {
    content: $zip-icon-mode_bus_auton;
  }
}

.zip-icon-mode_cablecar {
  &:before {
    content: $zip-icon-mode_cablecar;
  }
}

.zip-icon-mode_cycle {
  &:before {
    content: $zip-icon-mode_cycle;
  }
}

.zip-icon-mode_drive_drop {
  &:before {
    content: $zip-icon-mode_drive_drop;
  }
}

.zip-icon-mode_drive_parking {
  &:before {
    content: $zip-icon-mode_drive_parking;
  }
}

.zip-icon-mode_drive_parkride {
  &:before {
    content: $zip-icon-mode_drive_parkride;
  }
}

.zip-icon-mode_drive_traffic {
  &:before {
    content: $zip-icon-mode_drive_traffic;
  }
}

.zip-icon-mode_drive {
  &:before {
    content: $zip-icon-mode_drive;
  }
}

.zip-icon-mode_fly_stop {
  &:before {
    content: $zip-icon-mode_fly_stop;
  }
}

.zip-icon-mode_fly {
  &:before {
    content: $zip-icon-mode_fly;
  }
}

.zip-icon-mode_rail_stop {
  &:before {
    content: $zip-icon-mode_rail_stop;
  }
}

.zip-icon-mode_rail {
  &:before {
    content: $zip-icon-mode_rail;
  }
}

.zip-icon-mode_scooter {
  &:before {
    content: $zip-icon-mode_scooter;
  }
}

.zip-icon-mode_ship_stop {
  &:before {
    content: $zip-icon-mode_ship_stop;
  }
}

.zip-icon-mode_ship {
  &:before {
    content: $zip-icon-mode_ship;
  }
}

.zip-icon-mode_taxi {
  &:before {
    content: $zip-icon-mode_taxi;
  }
}

.zip-icon-mode_tram_stop {
  &:before {
    content: $zip-icon-mode_tram_stop;
  }
}

.zip-icon-mode_tram {
  &:before {
    content: $zip-icon-mode_tram;
  }
}

.zip-icon-mode_wait_long {
  &:before {
    content: $zip-icon-mode_wait_long;
  }
}

.zip-icon-mode_wait_short {
  &:before {
    content: $zip-icon-mode_wait_short;
  }
}

.zip-icon-mode_wait {
  &:before {
    content: $zip-icon-mode_wait;
  }
}

.zip-icon-mode_walk {
  &:before {
    content: $zip-icon-mode_walk;
  }
}

.zip-icon-poi_attraction {
  &:before {
    content: $zip-icon-poi_attraction;
  }
}

.zip-icon-poi_bar {
  &:before {
    content: $zip-icon-poi_bar;
  }
}

.zip-icon-poi_cafe {
  &:before {
    content: $zip-icon-poi_cafe;
  }
}

.zip-icon-poi_camping {
  &:before {
    content: $zip-icon-poi_camping;
  }
}

.zip-icon-poi_education {
  &:before {
    content: $zip-icon-poi_education;
  }
}

.zip-icon-poi_food {
  &:before {
    content: $zip-icon-poi_food;
  }
}

.zip-icon-poi_generic {
  &:before {
    content: $zip-icon-poi_generic;
  }
}

.zip-icon-poi_health {
  &:before {
    content: $zip-icon-poi_health;
  }
}

.zip-icon-poi_home {
  &:before {
    content: $zip-icon-poi_home;
  }
}

.zip-icon-poi_hospital {
  &:before {
    content: $zip-icon-poi_hospital;
  }
}

.zip-icon-poi_hotel {
  &:before {
    content: $zip-icon-poi_hotel;
  }
}

.zip-icon-poi_library {
  &:before {
    content: $zip-icon-poi_library;
  }
}

.zip-icon-poi_petrol {
  &:before {
    content: $zip-icon-poi_petrol;
  }
}

.zip-icon-poi_shop_generic {
  &:before {
    content: $zip-icon-poi_shop_generic;
  }
}

.zip-icon-poi_shop_supermarket {
  &:before {
    content: $zip-icon-poi_shop_supermarket;
  }
}

.zip-icon-poi_sport {
  &:before {
    content: $zip-icon-poi_sport;
  }
}

.zip-icon-poi_theatre {
  &:before {
    content: $zip-icon-poi_theatre;
  }
}

.zip-icon-poi_work {
  &:before {
    content: $zip-icon-poi_work;
  }
}

.zip-icon-poi_worship {
  &:before {
    content: $zip-icon-poi_worship;
  }
}

.zip-icon-wea_airqual1 {
  &:before {
    content: $zip-icon-wea_airqual1;
  }
}

.zip-icon-wea_airqual2 {
  &:before {
    content: $zip-icon-wea_airqual2;
  }
}

.zip-icon-wea_cloudy_light {
  &:before {
    content: $zip-icon-wea_cloudy_light;
  }
}

.zip-icon-wea_cloudy_overcast {
  &:before {
    content: $zip-icon-wea_cloudy_overcast;
  }
}

.zip-icon-wea_cloudy_windy {
  &:before {
    content: $zip-icon-wea_cloudy_windy;
  }
}

.zip-icon-wea_cloudy {
  &:before {
    content: $zip-icon-wea_cloudy;
  }
}

.zip-icon-wea_hall {
  &:before {
    content: $zip-icon-wea_hall;
  }
}

.zip-icon-wea_moon_full {
  &:before {
    content: $zip-icon-wea_moon_full;
  }
}

.zip-icon-wea_moon_half {
  &:before {
    content: $zip-icon-wea_moon_half;
  }
}

.zip-icon-wea_rain_heavy {
  &:before {
    content: $zip-icon-wea_rain_heavy;
  }
}

.zip-icon-wea_rain {
  &:before {
    content: $zip-icon-wea_rain;
  }
}

.zip-icon-wea_snow_light {
  &:before {
    content: $zip-icon-wea_snow_light;
  }
}

.zip-icon-wea_snow {
  &:before {
    content: $zip-icon-wea_snow;
  }
}

.zip-icon-wea_storm_heavy {
  &:before {
    content: $zip-icon-wea_storm_heavy;
  }
}

.zip-icon-wea_storm_thunder {
  &:before {
    content: $zip-icon-wea_storm_thunder;
  }
}

.zip-icon-wea_sun_fog {
  &:before {
    content: $zip-icon-wea_sun_fog;
  }
}

.zip-icon-wea_sun_full {
  &:before {
    content: $zip-icon-wea_sun_full;
  }
}

.zip-icon-wea_sun_mist {
  &:before {
    content: $zip-icon-wea_sun_mist;
  }
}

.zip-icon-wea_sun_partial {
  &:before {
    content: $zip-icon-wea_sun_partial;
  }
}

.zip-icon-wea_wind_extreme {
  &:before {
    content: $zip-icon-wea_wind_extreme;
  }
}

.zip-icon-wea_wind {
  &:before {
    content: $zip-icon-wea_wind;
  }
}

.zip-icon-jp_repeat {
  &:before {
    content: $zip-icon-jp_repeat;
  }
}

.zip-icon-int_edit {
  &:before {
    content: $zip-icon-int_edit;
  }
}

.zip-icon {
  display: block;

  &--margin-left-small {
    margin-left: 2px;
  }

  &--margin-right-small {
    margin-right: 2px;
  }

  &--rounded {
    text-align: center;
    color: white;

    &:before {
      line-height: 30px;
    }
  }

  &--bg-grey {
    background-color: #cfcfcf;
  }
}