@import "./theme/index.scss";

* {
  box-sizing: border-box;
}

button.ZPBT-button {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0;
  border-radius: 7px;
  color: #fff;
  background: $primary-color-btn;
  cursor: pointer;
  border: none;
  transition: transform 0.2s ease-in-out;
}

button[disabled] {
  opacity: 0.4;
  cursor: default;

  &:hover {
    transform: translateY(0);
    box-shadow: none;
  }
}
