:root {
    --zipabout-purple: #a48ee4;
    --zipabout-blue: #5bbef9;
    --zipabout-orange: #cc8001;
    --zipabout-red: #b72f1d;
    --zipabout-yellow: #d7c20b;
    --zipabout-purple: #a48ee4;
    --zipabout-grey: #b8c8d7;
    --zipabout-light-grey: #ededed;
}

// ---------- Colors ---------- //
$primary-color: var(--primary-color);
$primary-color-btn: var(--primary-color-button);
$danger-color: var(--danger-color);
$warning-color: var(--warning-color);

// ---------- Font Sizes ---------- //

// Headings
$heading-1: 30px;

// Paragraphs
$paragraph-1: 20px;

// Inputs
$input-1: 20px;
$input-2: 18px;

// Labels
$label-1: 15px;

// ---------- Font Weight ---------- //
$font-black: 900;
$font-bold: 700;
$font-medium: 500;
$font-regular: 400;
$font-light: 300;
$font-thin: 100;

// ---------- Spaces ---------- //
$space-5: 5px;
$space-10: 10px;
$space-15: 15px;
$space-20: 20px;
$space-25: 25px;
$space-30: 30px;
$space-35: 35px;
$space-40: 40px;
