@font-face {
  font-family: "CoHeadline";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Rg.woff") format("woff");
}
@font-face {
  font-family: "CoHeadline-bold";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Bd.woff") format("woff");
}

@font-face {
  font-family: "CoHeadline";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Rg.woff2") format("woff2");
}
@font-face {
  font-family: "CoHeadline-bold";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Bd.woff2") format("woff2");
}

@font-face {
  font-family: "CoHeadline";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Rg.ttf") format("truetype");
}
@font-face {
  font-family: "CoHeadline-bold";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Bd.ttf") format("truetype");
}

@font-face {
  font-family: "CoHeadline";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Rg.otf") format("opentype");
}
@font-face {
  font-family: "CoHeadline-bold";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Bd.otf") format("opentype");
}

@font-face {
  font-family: "CoHeadline";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Rg.eot") format("embedded-opentype");
}
@font-face {
  font-family: "CoHeadline-bold";
  src: url("../../assets/fonts/co-headline/CoHeadline_W_Bd.eot") format("embedded-opentype");
}

@font-face {
  font-family: "Helvetica";
  src: url("../../assets/fonts/Helvetica/Helvetica.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 700;
  src: url("../../assets/fonts/Helvetica/HelveticaNeue-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 500;
  src: url("../../assets/fonts/Helvetica/HelveticaNeue-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 400;
  src: url("../../assets/fonts/Helvetica/HelveticaNeue.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 300;
  src: url("../../assets/fonts/Helvetica/HelveticaNeue-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 200;
  src: url("../../assets/fonts/Helvetica/HelveticaNeue-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 100;
  src: url("../../assets/fonts/Helvetica/HelveticaNeue-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts//Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts//Roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Museo";
  src: url("../../assets/fonts/MuseoSans/6621071c-80c6-45c4-b2ae-dd60ffdc754f.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Museo";
  src: url("../../assets/fonts/MuseoSans/ef83c129-95f1-4ac7-8aea-874751794017.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Museo";
  src: url("../../assets/fonts/MuseoSans/3619b5f4-8df9-44f5-8c53-b7c236e9c74e.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Arial";
  src: url("../../assets/fonts/Arial/ARIAL.woff") format("woff");
}
